@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;500;600;700&display=swap");

html,
body,
#root {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: auto;
  overflow-x: hidden;
  font-family: "Lato", sans-serif !important;
  font-size: 18px;
  text-align: justify !important;
}
