:root {
  --white-color: #fff;
  --black-color: #3d3c3c;
  --grey-color: #b4b2b2;
  --green-color: #2c786c;
  --green-color-80: #105749ff;
  --dark-green-color: #004445;
  --orange-color: #ffad61ff;
  --background-color-yellow: #fffef3ff;
  --background-color-grey: #f5f5f5;
  --light-green-color: #faf5e4;
  --width-350: 350px;
}

h1 {
  font-size: 2.5em;
}

.white-color {
  color: var(--white-color) !important;
}

.black-color {
  color: var(--black-color) !important;
}

.grey-color {
  color: var(--grey-color) !important;
}

.orange-color {
  color: var(--orange-color) !important;
}

.green-color-80 {
  color: var(--green-color-80) !important;
}

.white-color-background {
  background: var(--white-color) !important;
}

.green-color-80-background {
  background: var(--green-color-80) !important;
}

.green-color-80-border {
  border-color: var(--green-color-80) !important;
}

.width-350 {
  width: var(--width-350);
}

/* Antd styling */

.ant-layout-header {
  background: var(--white-color) !important;
}

.ant-layout-content {
  min-height: calc(100vh - 135px) !important;
}

.ant-menu-horizontal {
  border-bottom: none !important;
}

.ant-layout-footer {
  background: var(--white-color) !important;
}

.ant-menu-submenu-popup ul li span a {
  font-size: 15px;
  font-style: normal;
  line-height: 1.5;
  text-transform: none;
  text-decoration: none;
  color: var(--green-color-80) !important;
}

.ant-menu-submenu-popup ul .ant-menu-item-active,
.ant-menu-submenu-popup ul .ant-menu-item-selected {
  color: var(--green-color-80);
  background: var(--background-color-grey) !important;
}

.ant-pagination {
  margin: 10px auto !important;
}

/* Generic classes */

.d-flex {
  display: flex !important;
}

.d-contents {
  display: contents !important;
}

.d-grid {
  display: grid !important;
}

.text-align-center {
  text-align: center !important;
}

.align-self-center {
  align-self: center;
}

.m-top-10 {
  margin-top: 10% !important;
}

.m-top-5 {
  margin-top: 5% !important;
}

.m-top-3 {
  margin-top: 3% !important;
}

.m-auto {
  margin: auto !important;
}

.m-auto-lr-10-tb {
  margin: 10% auto;
}

.m-auto-lr-5-tb {
  margin: 5% auto;
}

.m-auto-tb {
  margin: auto 0;
}

.width-75 {
  width: 75% !important;
}

.just-content-center {
  justify-content: center !important;
}

h1 {
  font-size: 40px; /* Default font size */
}

/* Media query for smaller screens */
@media screen and (max-width: 1268px) {
  h1 {
    font-size: 30px; /* Adjust font size for smaller screens */
  }
  .ant-card-head-title {
    font-size: 20px !important;
  }
}

/* Media query for smaller screens */
@media screen and (max-width: 768px) {
  h1 {
    font-size: 20px; /* Adjust font size for smaller screens */
  }
  .ant-card-head-title {
    font-size: 18px !important;
  }
}

/* Other classes */

.page-title {
  padding: 0px 0px 6px 0px;
  color: #050303;
  line-height: 46px;
  border-bottom: #28ae00 solid 1px;
  margin: 30px auto;
}

.scaled-image {
  max-width: 100%; /* Allow the image to scale down to fit the container width */
  max-height: 100vh; /* Set a maximum height for the image */
  width: auto; /* Let the image's width adapt to maintain its aspect ratio */
  height: auto; /* Allow the image's height to scale proportionally */
  object-fit: cover;
}

.white-space .ant-card-head-title {
  white-space: break-spaces;
}